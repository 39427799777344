.search-modal .modal-wrapper {
	/* background: #222; */
	bottom: 0;
	height: 90vh;
	width: 95vw;
	border-radius: 10px;
}
/*  */

.clickable_hover:hover {
	cursor: pointer;
}

// .show {
// 	display: flex;
// }

// .hide {
// 	display: none;
// }
ion-popover {
	--background: #fff;
}

.episodes-popover {
	--background: #fff;
}

.video-player {
	/* Customize the player container */
	width: 100%;
	// max-width: 500px;

	/* Customize the video element */
	video {
		width: 100%;
		background-color: black; /* Set a background color for the video container */
	}

	/* Customize the player controls */
	.controls {
		/* Style the control bar */
		background-color: rgba(0, 0, 0, 0.7);
		color: white;
		padding: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		/* Style the control buttons */
		button {
			background-color: transparent;
			border: none;
			color: white;
			font-size: 16px;
			cursor: pointer;
			padding: 5px;
			transition: opacity 0.3s ease;

			&:hover {
				opacity: 0.7;
			}
		}
	}
}

// .popup-episode-slider {
// 	position: absolute;
// 	// top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100px;
// 	background: rgba(0, 0, 0, 0.7);
// 	z-index: 9999;
// 	// item side by side
// 	flex-direction: row;
// 	flex-wrap: wrap;
// 	justify-content: center;
// 	align-items: center;
// 	.show {
// 		display: flex;
// 	}
// 	.hide {
// 		display: none;
// 	}
// }
@media (prefers-color-scheme: dark) {
	ion-backdrop {
		background-color: #fff;
	}
}

@media (prefers-color-scheme: light) {
	ion-backdrop {
		background-color: #000;
	}
}

ion-tab-bar {
	--border: "0";
}

@font-face {
	font-family: "csnpwdtNFI";
	src: local("csnpwdtNFI"), url(../fonts/csnpwdtNFI.ttf) format("truetype");
}

@font-face {
	font-family: "FredokaOne";
	src: local("FredokaOne"),
		url(../fonts/FredokaOne-Regular.ttf) format("truetype");
}

/* ion-slides {
  height: 100%;
} */

.center {
	text-align: center;
}

.scroller {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
}

.big-scroll-container {
	height: auto;
	width: 100%;
}

.big-scroller {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
}

/* ion-toolbar.tranlucent {
 border-width: 0;
 background: transparent;
}

ion-toolbar.tranlucent {
  opacity: 0.70;
  filter: alpha(opacity=70);
  -ms-filter: 'alpha(opacity=70)';
  -khtml-opacity: 0.70;
  -moz-opacity: 0.70;
}
ion-toolbar.tranlucent ion-buttons ion-button {
  opacity: 1.0;
}

ion-toolbar.tranlucent ion-title {
  opacity: 1.0;
}

ion-toolbar {
  background: transparent;
  background-color: transparent;
}

.Tranlucent {
  opacity: 0.70;
  filter: alpha(opacity=70);
  -ms-filter: 'alpha(opacity=70)';
  -khtml-opacity: 0.70;
  -moz-opacity: 0.70;
} */

.scroller {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
}

.no-scrollbar::-webkit-scrollbar-track {
	display: none;
}

:root::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

.login-form {
	text-align: center;
}
