@media screen and (max-width: 780px) {
  .DesktopToolbarButtons {
    display: none;
  }
  .MobileTabbar {
    display: auto;
  }
  .MobileToolbarButtons {
    display: auto;
  }
  .MobileTopTitle {
    display: auto;
  }
  .DesktopFooter {
    display: none;
  }
}

@media screen and (min-width: 780px) {
  .DesktopFooter {
    display: auto;
  }
  .DesktopToolbarButtons {
    display: auto;
  }
  .MobileTabbar {
    display: none;
  }
  .MobileToolbarButtons {
    display: none;
  }
  .MobileTopTitle {
    display: none;
  }
}

@media (prefers-color-scheme: dark) {
  .logo-light {
    display: none;
  }
  .darkMode {
    color: white;
  }
  .darkModeText {
    color: #d0d0d0;
  }
  .dark-backdrop-div {
    background-color: rgba(#000000,0.35);
  }
}

@media (prefers-color-scheme: light) {
  .logo-dark {
    display: none;
  }
  .darkModeText {
    color: #2d2d2d;
  }
  .darkMode {
    color: black;
  }
  .dark-backdrop-div {
    background-color: rgba(255,255,255, 0.25);
  }
}

/* // ion-content {
//   -webkit-overflow-scrolling: touch;
//   scrollbar-width: '20px';
//   scroll-behavior: smooth;
//   --offset-bottom: auto !important;
//   // --overflow: hidden;
//   overflow: auto;
//   &::-webkit-scrollbar {
//     color: :;
//   }
// } */

/* // div:hover {
//   -web ll-behavior: smooth;
//   --offset-bottom: auto !important;
//   --overflow: auto;
//   overflow: auto;
//   &::-webkit-scrollbar {
//     display: auto;
//   }
// } */

/* // @media screen and (max-width: "780px") {
// ion-content {
//   -webkit-overflow-scrolling: touch;
//   scrollbar-width: none;
//   scroll-behavior: smooth;
//   --offset-bottom: auto !important;
//   --overflow: hidden;
//   overflow: auto;

//   &::-webkit-scrollbar {
//     display: none;
//   }
// }
// } */

/* // @media screen and (min-width: "780px") {
// ion-content {
//   -webkit-overflow-scrolling: touch;
//   scroll-behavior: smooth;
//   --offset-bottom: auto !important;
//   --overflow: hidden;
//   overflow: auto;
// }
// @media (prefers-color-scheme: dark) {
// ion-content {
//   scrollbar-color: #000000 #131313;
//   -webkit-overflow-scrolling: touch;
//   scroll-behavior: smooth;
//   --offset-bottom: auto !important;
//   --overflow: hidden;
//   overflow: auto;
// }
// }
// }

// .noScrollbar {
//   &::-webkit-scrollbar {
//     display: none;
//   }
// } */
